import React from "react";
import logo from "../images/side-spray-tr2.png";

function Splash() {
  return (
    <>
      <div className="splash-page">
        <div className="logo-container-c">
          <img src={logo} alt="logo" className="app--logo-image" />
        </div>
      </div>
    </>
  );
}

export default Splash;
