import React from "react";
import TypeWriter from "react-typewriter";

class TypingText extends React.Component {
  render() {
    return (
      // Passing in text.

      <div>
        {/* <TypeWriter typing={1.5} class={"typewriter"}>
       Side Pattern is an art, design & media office based in Amsterdam. It
          advances an approach to art and cultural production that reflects
          what's happening outside and in our community. Reflective of over a
          decade initiating, consulting and leading on projects across art,
          design, culture, fashion, media and education the office provides a
          bespoke consultancy service rooted in an ongoing line of cultural
          research. Featured in Guap Magazine & The Black Discourse. Selected
          partners and clients include Nike, New Balance, Daily Paper, Double A
          Magazine, Nieuwe Instituut, Dutch Design Week, Sandberg Instituut,
          Design Academy Eindhoven, British Council, Whitechapel Gallery,
          University of Bristol & International Curators Forum. Side Pattern is
          currently Kirtis Clarke, Zaina Pakabomba & Liam Wilson alongside a
          network of artists, cultural practitioners, designers and researchers.
        </TypeWriter> */}
        <p>
          Side Pattern is an art, design & media office based in Amsterdam. It
          advances an approach to art and cultural production that reflects
          what's happening outside and in our community. Reflective of over a
          decade initiating, consulting and leading on projects across art,
          design, culture, fashion, media and education the office provides a
          bespoke consultancy service rooted in an ongoing line of cultural
          research. Featured in Guap Magazine & The Black Discourse. Selected
          partners and clients include Nike, New Balance, Daily Paper, Double A
          Magazine, Nieuwe Instituut, Dutch Design Week, Sandberg Instituut,
          Design Academy Eindhoven, British Council, Whitechapel Gallery,
          University of Bristol & International Curators Forum. Side Pattern is
          currently Kirtis Clarke, Zaina Pakabomba & Liam Wilson alongside a
          network of artists, cultural practitioners, designers and researchers.
        </p>
        <p>For enquiries please mail info@sidepattern.com</p>
      </div>
    );
  }
}

export default TypingText;
