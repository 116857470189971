import React from "react";
import TimeDate from "./TimeDate";

function Footer() {
  return (
    <>
      <div className="footer">
        <TimeDate />
      </div>
    </>
  );
}

export default Footer;
